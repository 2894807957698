import {
  ColourCode,
  ElementKind,
  EnglishLevel,
  EnglishSkill,
  EventCategory,
  EventEnglishLevel,
  EventPermissions,
  Interest,
  LessonKind,
  MembershipPlans,
  SubscriptionStatus,
  TopicStatus,
  UserBadge,
  UserBadgeType,
} from "../types/graphql";

export const DEBOUNCE_TIME = 300;

export const DEFAULT_OFFSET = 0;

export const PAGE_DEFAULT = 0;
export const ROWS_PER_PAGE_DEFAULT = 25;
export const ROWS_PER_PAGE_OPTIONS = [10, 25, 50];

export const EVENT_PAGE_DATE_FORMAT = "EEEE, MMM do yy";
export const EVENT_PAGE_TIME_FORMAT = "p";

export const LESSON_NAMES = new Map<LessonKind, string>([
  [LessonKind.Overview, "Overview"],
  [LessonKind.Vocabulary, "Vocabulary"],
  [LessonKind.DeepDive, "Deep Dive"],
  [LessonKind.Custom, "Custom"],
]);

export const TOPIC_STATUS = new Map<TopicStatus, string>([
  [TopicStatus.Current, "Current"],
  [TopicStatus.ComingSoon, "Coming Soon"],
  [TopicStatus.Inactive, "Inactive"],
]);

export const INTERESTS_NAMES = new Map<Interest, string>([
  [Interest.AnimalsAndWildlife, "Animals and wildlife"],
  [Interest.ArtsAndCrafts, "Arts and crafts"],
  [Interest.BeautyAndFashion, "Beauty and fashion"],
  [Interest.BusinessAndEntrepreneurship, "Business and entrepreneurship"],
  [Interest.CareersAndProfessionalDevelopment, "Careers and professional development"],
  [Interest.DifferentCultures, "Different cultures"],
  [Interest.Design, "Design"],
  [Interest.Economics, "Economics"],
  [Interest.EnvironmentalIssues, "Environmental issues"],
  [Interest.Equality, "Equality"],
  [Interest.FamilyAndMotherhood, "Family and motherhood"],
  [Interest.FeminismAndEmpowerment, "Feminism and empowerment"],
  [Interest.FoodAndCooking, "Food and cooking"],
  [Interest.Games, "Games"],
  [Interest.GoalsSettingAndSuccess, "Goals setting and success"],
  [Interest.HealthAndWellness, "Health and wellness"],
  [Interest.Hiking, "Hiking"],
  [Interest.History, "History"],
  [Interest.HomeAndGardening, "Home and gardening"],
  [Interest.Languages, "Languages"],
  [Interest.Ielts, "IELTS"],
  [Interest.Leadership, "Leadership"],
  [Interest.LivingAbroad, "Living abroad"],
  [Interest.LoveAndRelationships, "Love and relationships"],
  [Interest.MoviesAndTvShows, "Movies and TV shows"],
  [Interest.MusicAndDance, "Music and dance"],
  [Interest.NatureAndTheEnvironment, "Nature and environment"],
  [Interest.PersonalDevelopment, "Personal development"],
  [Interest.Photography, "Photography"],
  [Interest.Politics, "Politics"],
  [Interest.Psychology, "Psychology"],
  [Interest.Reading, "Reading"],
  [Interest.ScienceAndTechnology, "Science and technology"],
  [Interest.Spirituality, "Spirituality"],
  [Interest.Sports, "Sports"],
  [Interest.SustainableLiving, "Sustainable living"],
  [Interest.TheatreAndPerformance, "Theatre and performance"],
  [Interest.Travel, "Travel"],
  [Interest.VideoGames, "Video games"],
  [Interest.VolunteeringAndCharity, "Volunteering and charity"],
  [Interest.Writing, "Writing"],
]);

export const ENGLISH_SKILLS_NAMES = new Map<EnglishSkill, string>([
  [EnglishSkill.GivingAPresentation, "Giving a presentation"],
  [EnglishSkill.InterviewOrBeInterviewed, "Interview or be interviewed"],
  [EnglishSkill.IntroduceMyself, "Introduce myself"],
  [EnglishSkill.MeetNewPeople, "Meet new people"],
  [EnglishSkill.NegotiateAndDebate, "Negotiate and debate"],
  [EnglishSkill.PassAnEnglishExam, "Pass an English exam"],
  [EnglishSkill.PersonalNetworking, "Personal networking"],
  [EnglishSkill.Pronunciation, "Pronunciation"],
  [EnglishSkill.SpeakConfidentlyAtWork, "Speak confidently at work"],
  [EnglishSkill.ThinkInEnglish, "Think in English"],
  [EnglishSkill.UnderstandMoviesAndMusic, "Understand movies and music"],
  [EnglishSkill.UnderstandNativeSpeakers, "Understand native speakers"],
  [EnglishSkill.WriteCreatively, "Write creatively"],
  [EnglishSkill.WriteProfessionally, "Write professionally"],
]);

export const ENGLISH_LEVELS = new Map<EnglishLevel, string>([
  [EnglishLevel.A1, "A1"],
  [EnglishLevel.A2, "A2"],
  [EnglishLevel.B1, "B1"],
  [EnglishLevel.B2, "B2"],
  [EnglishLevel.C1, "C1"],
  [EnglishLevel.C2, "C2"],
]);

export enum BooleanOptions {
  True = "true",
  False = "false",
}

export const OPEN_TO_SPEAKING_PARTNERS = new Map<BooleanOptions, string>([
  [BooleanOptions.True, "Open to speaking partners"],
  [BooleanOptions.False, "Not open to speaking partners"],
]);

export const VERIFICATION_STATUSES = new Map<BooleanOptions, string>([
  [BooleanOptions.True, "Verified"],
  [BooleanOptions.False, "Not verified"],
]);

export const USER_BADGES = new Map<UserBadge, string>([
  [UserBadge.InactiveMember, "Inactive Member"],
  [UserBadge.LittleSister, "Little Sister"],
  [UserBadge.Member, "Member"],
  [UserBadge.BigSister, "Big Sister"],
  [UserBadge.EnglishCoach, "English Coach"],
  [UserBadge.CommunitySuccess, "Community Success"],
  [UserBadge.Founder, "Founder"],
  [UserBadge.MemberSupport, "Member Support"],
  [UserBadge.TechnicalSupport, "Technical Support"],
]);

export const USER_BADGE_TYPES = new Map<UserBadgeType, string>([
  [UserBadgeType.ArticlesPublished_1, "Author"],
  [UserBadgeType.BigSister, "Big Sister"],
  [UserBadgeType.EventsParticipated_10, "10 Events"],
  [UserBadgeType.EventsParticipated_100, "100 Club"],
  [UserBadgeType.EventsParticipated_500, "500 Club"],
  [UserBadgeType.EventsParticipated_1000, "Conversation Queen"],
  [UserBadgeType.FoundingMember, "Founding Member"],
  [UserBadgeType.Hosted_1, "Host"],
  [UserBadgeType.Hosted_10, "Hosted 10 Events"],
  [UserBadgeType.Hosted_50, "Hosted 50 Events"],
  [UserBadgeType.Hosted_100, "Super Host"],
  [UserBadgeType.Hosted_500, "Ultra Host"],
  [UserBadgeType.LegendaryLady, "Legendary Lady"],
  [UserBadgeType.Membership_1, "One Year Member"],
  [UserBadgeType.Membership_2, "Two Year Member"],
  [UserBadgeType.Membership_3, "Three Year Member"],
  [UserBadgeType.Verified, "Verified"],
  [UserBadgeType.PerfectProfile, "Perfect Profile"],
  [UserBadgeType.HeyLadyWay, "Hey Lady! Way"],
  [UserBadgeType.CertifiedHost, "Certified Host"],
  [UserBadgeType.BroughtAFriend, "Brought a Friend"],
  [UserBadgeType.Ambassador, "Ambassador"],
  [UserBadgeType.PlatinumAmbassador, "Platinum Ambassador"],
  [UserBadgeType.DiamondAmbassador, "Diamond Ambassador"],
]);

export const ASSIGNED_BADGES = [UserBadgeType.LegendaryLady, UserBadgeType.CertifiedHost];

export const USER_PERMISSIONS = new Map<EventPermissions, string>([
  [EventPermissions.BigSister, "Big Sister"],
  [EventPermissions.HeyLadyAdmin, "Hey Lady Admin"],
  [EventPermissions.HeyLadyTeam, "Hey Lady Team"],
  [EventPermissions.InactiveMember, "Inactive Member"],
  [EventPermissions.OneToThree, "1 - 3 Months"],
  [EventPermissions.ThreeToTwelve, "3 - 12 Months"],
  [EventPermissions.MoreThanTwelveMonths, "More than 12 months"],
  [EventPermissions.ZeroToOne, "0 - 1 Months"],
]);

export const SUBSCRIPTION_TYPES = new Map<MembershipPlans, string>([
  [MembershipPlans.Monthly, "Monthly"],
  [MembershipPlans.Quarterly, "Quarterly"],
  [MembershipPlans.Annual, "Annual"],
]);

export const SUBSCRIPTION_STATUSES = new Map<SubscriptionStatus, string>([
  [SubscriptionStatus.Active, "Active"],
  [SubscriptionStatus.Cancelled, "Cancelled"],
  [SubscriptionStatus.InTrial, "In Trial"],
  [SubscriptionStatus.NonRenewing, "Non Renewing"],
  [SubscriptionStatus.Paused, "Paused"],
]);

export const PERMISSION_BADGE_DEPENDENCY = new Map<EventPermissions, UserBadge[]>([
  [EventPermissions.BigSister, [UserBadge.BigSister]],
  [
    EventPermissions.HeyLadyAdmin,
    [
      UserBadge.EnglishCoach,
      UserBadge.CommunitySuccess,
      UserBadge.Founder,
      UserBadge.MemberSupport,
      UserBadge.TechnicalSupport,
    ],
  ],
  [EventPermissions.HeyLadyTeam, [UserBadge.EnglishCoach]],
  [EventPermissions.InactiveMember, [UserBadge.InactiveMember]],
  [EventPermissions.OneToThree, [UserBadge.Member]],
  [EventPermissions.ThreeToTwelve, [UserBadge.Member]],
  [EventPermissions.MoreThanTwelveMonths, [UserBadge.Member]],
  [EventPermissions.ZeroToOne, [UserBadge.LittleSister]],
]);

export const LESSON_PATHS = new Map<LessonKind, string>([
  [LessonKind.Overview, "overview"],
  [LessonKind.Vocabulary, "vocabulary/1"],
  [LessonKind.DeepDive, "deep-dive/1"],
]);

export const ELEMENT_TEMPLATES_TYPE = "TEMPLATES";

export const ELEMENT_TEMPLATES = new Map<ElementKind, any>([
  [ElementKind.Title, { kind: ElementKind.Title, value: undefined }],
  [ElementKind.Video, { kind: ElementKind.Video, value: undefined }],
  [ElementKind.Paragraph, { kind: ElementKind.Paragraph, value: undefined }],
  [ElementKind.Questions, { kind: ElementKind.Questions, value: [] }],
  [ElementKind.Divider, { kind: ElementKind.Divider, value: undefined }],
  [ElementKind.Spacing, { kind: ElementKind.Spacing, value: undefined }],
  [ElementKind.Image, { kind: ElementKind.Image, value: undefined }],
  [ElementKind.Attachment, { kind: ElementKind.Attachment, value: undefined }],
  [ElementKind.Links, { kind: ElementKind.Links, value: [] }],
  [ElementKind.Ideas, { kind: ElementKind.Ideas, value: [] }],
  [ElementKind.Layout, { kind: ElementKind.Layout, value: [] }],
  [ElementKind.Grid, { kind: ElementKind.Grid, value: [] }],
  [ElementKind.Button, { kind: ElementKind.Button, value: [] }],
  [ElementKind.Banner, { kind: ElementKind.Banner, value: [] }],
  [ElementKind.ImageAndButton, { kind: ElementKind.ImageAndButton, value: [] }],
  [ElementKind.Embedded, { kind: ElementKind.Embedded, value: [] }],
]);

export const EVENT_ENGLISH_LEVEL_NAMES = new Map<EventEnglishLevel, string>([
  [EventEnglishLevel.Intermediate, "Intermediate"],
  [EventEnglishLevel.Advanced, "Advanced"],
  [EventEnglishLevel.Everyone, "Everyone"],
]);

export const EVENT_CATEGORY_HLT_EVENTS = new Map<EventCategory, string>([
  [EventCategory.Conversation, "Conversation"],
  [EventCategory.None, "None"],
  [EventCategory.Professional, "Professional"],
  [EventCategory.StudyAndLearn, "StudyAndLearn"],
]);

export const EVENT_CATEGORY_MEMBER_EVENTS = new Map<EventCategory, string>([
  [EventCategory.None, "None"],
  [EventCategory.Professional, "Professional"],
]);

export const VIDEO_SET_COLOURS = new Map<ColourCode, string>([
  [ColourCode.Pink, "#FF66A7"],
  [ColourCode.Orange, "#FF9266"],
  [ColourCode.Green, "#2B8740"],
  [ColourCode.Blue, "#32B3F7"],
  [ColourCode.Red, "#FF4653"],
]);

export enum TopicButtonType {
  Link = "Link",
  Video = "Pop-Up Video",
}

export enum TopicButtonAlignment {
  Left = "Left",
  Center = "Center",
  Right = "Right",
}

export enum GridColumns {
  TwoEqualColumns = "TwoEqualColumns",
  TreeEqualColumns = "TreeEqualColumns",
  OneThirdTwoThirds = "OneThirdTwoThirds",
  TwoThirdsOneThird = "TwoThirdsOneThird",
  OneFourthThreeFourths = "OneFourthThreeFourths",
  ThreeFourthsOneFourth = "ThreeFourthsOneFourth",
}

export enum MoveElement {
  UP = "Up",
  DOWN = "Down",
}

export enum MembersType {
  LittleSisters = "Little Sisters",
  Members = "Members",
}

export const SLIDER_AUDIENCE = new Map<EventPermissions[], MembersType>([
  [[EventPermissions.ZeroToOne], MembersType.LittleSisters],
  [
    [
      EventPermissions.OneToThree,
      EventPermissions.ThreeToTwelve,
      EventPermissions.MoreThanTwelveMonths,
      EventPermissions.BigSister,
      EventPermissions.HeyLadyTeam,
      EventPermissions.HeyLadyAdmin,
    ],
    MembersType.Members,
  ],
]);

export enum UrlFilterParams {
  Subscription = "subscriptionStatus",
  Plan = "plan",
  Kind = "kind",
  Location = "location",
  Interests = "interests",
  Skills = "englishSkills",
  Levels = "englishLevels",
  Open = "openToSpeakingPartners",
  Occupations = "occupations",
  Badges = "badges",
  VerificationStatus = "verificationStatus",
}
