import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import debounce from "lodash.debounce";
import React from "react";

import { DEBOUNCE_TIME } from "../helpers/const";
import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  value?: string;
  setValue: (text: string) => void;
}

const LessonEditPageSectionVideo: React.FC<Props> = ({ value, setValue }: Props) => {
  const onChange = debounce(setValue, DEBOUNCE_TIME);

  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s}>
        <FormLabel>Video URL</FormLabel>
      </Box>
      <TextField
        fullWidth
        multiline
        defaultValue={value}
        name="videoUrl"
        placeholder="Video URL"
        variant="outlined"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => onChange(target.value)}
      />
    </FormControl>
  );
};

export default LessonEditPageSectionVideo;
