import { Box, Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import React from "react";
import { AdminUsersOrderBy, SortDirection } from "src/types/enum";

interface Props {
  onRequestSort: (event: React.MouseEvent<unknown>, property: AdminUsersOrderBy) => void;
  order: SortDirection;
  orderBy: AdminUsersOrderBy;
  onSelectCurrentPage: () => void;
  selectCurrentPageChecked: boolean;
  shouldClearSelection: boolean;
}

const EnhancedTableHead: React.FC<Props> = ({
  onRequestSort,
  order,
  orderBy,
  onSelectCurrentPage,
  selectCurrentPageChecked,
  shouldClearSelection,
}: Props) => {
  const createSortHandler = (property: AdminUsersOrderBy) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            checked={selectCurrentPageChecked}
            indeterminate={shouldClearSelection}
            onChange={onSelectCurrentPage}
          />
        </TableCell>

        <TableCell sortDirection={orderBy === AdminUsersOrderBy.Name ? order : false}>
          <TableSortLabel
            active={orderBy === AdminUsersOrderBy.Name}
            direction={orderBy === AdminUsersOrderBy.Name ? order : SortDirection.ASC}
            onClick={createSortHandler(AdminUsersOrderBy.Name)}
          >
            Name
            {orderBy === AdminUsersOrderBy.Name ? (
              <Box component="span" sx={visuallyHidden}>
                {order === SortDirection.DESC ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>

        <TableCell>Community role</TableCell>

        <TableCell sortDirection={orderBy === AdminUsersOrderBy.Email ? order : false}>
          <TableSortLabel
            active={orderBy === AdminUsersOrderBy.Email}
            direction={orderBy === AdminUsersOrderBy.Email ? order : SortDirection.ASC}
            onClick={createSortHandler(AdminUsersOrderBy.Email)}
          >
            E-mail
            {orderBy === AdminUsersOrderBy.Email ? (
              <Box component="span" sx={visuallyHidden}>
                {order === SortDirection.DESC ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>

        <TableCell sortDirection={orderBy === AdminUsersOrderBy.Country ? order : false}>
          <TableSortLabel
            active={orderBy === AdminUsersOrderBy.Country}
            direction={orderBy === AdminUsersOrderBy.Country ? order : SortDirection.ASC}
            onClick={createSortHandler(AdminUsersOrderBy.Country)}
          >
            Location
            {orderBy === AdminUsersOrderBy.Country ? (
              <Box component="span" sx={visuallyHidden}>
                {order === SortDirection.DESC ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>

        <TableCell sortDirection={orderBy === AdminUsersOrderBy.Date ? order : false}>
          <TableSortLabel
            active={orderBy === AdminUsersOrderBy.Date}
            direction={orderBy === AdminUsersOrderBy.Date ? order : SortDirection.ASC}
            onClick={createSortHandler(AdminUsersOrderBy.Date)}
          >
            Joined date
            {orderBy === AdminUsersOrderBy.Date ? (
              <Box component="span" sx={visuallyHidden}>
                {order === SortDirection.DESC ? "sorted descending" : "sorted ascending"}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>

        <TableCell align="right">Actions</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default EnhancedTableHead;
