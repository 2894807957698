import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import React from "react";
import { Spacing } from "src/types/enum";

/**
 * Types
 */
interface Props {
  value: string;
  setValue: (value: string) => void;
}

const LessonEditPageSectionEmbedded: React.FC<Props> = ({ value, setValue }: Props) => {
  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s}>
        <FormLabel>Paste HTML code</FormLabel>
      </Box>
      <TextField
        fullWidth
        defaultValue={value}
        name="embedded"
        variant="outlined"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => {
          setValue(target.value);
        }}
      />
    </FormControl>
  );
};

export default LessonEditPageSectionEmbedded;
