import { Box, FormControl, FormLabel } from "@mui/material";
import debounce from "lodash.debounce";
import React from "react";

import { DEBOUNCE_TIME } from "../helpers/const";
import { Spacing } from "../types/enum";

import RichTextEditor from "./RichTextEditor";

/**
 * Types
 */
interface Props {
  value?: string;
  setValue: (text: string) => void;
}

const LessonEditPageSectionParagraph: React.FC<Props> = ({ value, setValue }: Props) => {
  const onChange = debounce(setValue, DEBOUNCE_TIME);

  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s}>
        <FormLabel>Paragraph</FormLabel>
      </Box>
      <RichTextEditor value={value} onChange={onChange} />
    </FormControl>
  );
};

export default LessonEditPageSectionParagraph;
