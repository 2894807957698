import { Box, FormControlLabel, FormLabel, Radio, RadioGroup } from "@mui/material";
import React, { type ChangeEvent } from "react";

import makeStyles from "@mui/styles/makeStyles";

import { Spacing } from "../types/enum";
import { AttachmentKind, type AttachmentModel } from "../types/generic";

import FileDropzone from "./FileDropzone";

/**
 * Types
 */

interface State {
  kind?: AttachmentKind;
  attachment?: AttachmentModel;
}

interface Props {
  value: State;
  setValue: (value: State) => void;
}

/**
 * Styles
 */
const useStyles = makeStyles({
  root: {
    width: "100%",
  },
  group: {
    flexDirection: "row",
  },
});

const LessonEditPageSectionAttachment: React.FC<Props> = ({ value, setValue }: Props) => {
  const classes = useStyles();

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValue({
      attachment: value?.attachment,
      kind: event.target.value as AttachmentKind,
    });
  };

  return (
    <Box className={classes.root}>
      <Box mb={Spacing.s}>
        <FormLabel>Attachment</FormLabel>
      </Box>

      <FileDropzone
        fileName={value?.attachment?.url}
        onDropFile={(attachment: AttachmentModel) => {
          setValue({
            attachment,
            kind: value?.kind ?? AttachmentKind.Wordlist,
          });
        }}
      />

      <Box mt={Spacing.s}>
        <RadioGroup
          className={classes.group}
          value={value?.kind ?? AttachmentKind.Wordlist}
          onChange={onChange}
        >
          <FormControlLabel value={AttachmentKind.Wordlist} control={<Radio />} label="Wordlist" />
          <FormControlLabel value={AttachmentKind.Wordbook} control={<Radio />} label="Workbook" />
        </RadioGroup>
      </Box>
    </Box>
  );
};

export default LessonEditPageSectionAttachment;
