import {
  IconButton,
  Link,
  SvgIcon,
  TableCell,
  TableRow,
  type Theme,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { format } from "date-fns";
import React from "react";
import { Trash2 as DeleteIcon, Edit as EditIcon } from "react-feather";
import { Link as RouterLink } from "react-router-dom";

import { Spacing } from "../types/enum";
import { type ArticleModel } from "../types/graphql";

import { useOnOffSwitch } from "../helpers/hooks";

import ConfirmationModal from "./ConfirmationModal";
import Label from "./Label";

/**
 * Types
 */
interface Props {
  article: ArticleModel;
  onDelete: (articleId: string) => void;
}

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  title: {
    maxWidth: "240px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  image: {
    width: "auto",
    maxHeight: "150px",
    height: "auto",
    display: "block",
    borderRadius: theme.shape.borderRadius,
  },
  placeholder: {
    height: "100%",
    padding: theme.spacing(Spacing.ml),
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.action.disabled}`,
  },
  item: {
    maxWidth: "250px",
  },
}));

const ArticlesPageArticleDetails: React.FC<Props> = ({ article, onDelete }: Props) => {
  const classes = useStyles();

  const [open, onOpen, onClose] = useOnOffSwitch();

  return (
    <TableRow>
      <TableCell>
        <Link
          color="inherit"
          href={`${import.meta.env.VITE_URL}/memberzine/article/${article?.id}`}
        >
          <Typography variant="body2" className={classes.title}>
            {article.title}
          </Typography>
        </Link>
      </TableCell>
      <TableCell>
        {article.author ? (
          <Link component={RouterLink} to={`/users/${article.author?.id}`}>
            <Typography variant="body2">{article.author?.name}</Typography>
          </Link>
        ) : (
          <Typography variant="body2">Inactive member</Typography>
        )}
      </TableCell>

      <TableCell>
        <Typography variant="body2">
          {format(new Date(article?.publishedAt), "yyyy-MM-dd")}
        </Typography>
      </TableCell>

      <TableCell>
        {article.isFeatured ? (
          <Label variant="success">True</Label>
        ) : (
          <Label variant="error">False</Label>
        )}
      </TableCell>
      <TableCell>
        {article.isVisible ? (
          <Label variant="success">True</Label>
        ) : (
          <Label variant="error">False</Label>
        )}
      </TableCell>
      <TableCell align="right">
        <Link component={RouterLink} to={`/articles/edit/${article.id}`}>
          <IconButton size="large">
            <SvgIcon fontSize="small">
              <EditIcon />
            </SvgIcon>
          </IconButton>
        </Link>
        <IconButton onClick={onOpen} size="large">
          <SvgIcon fontSize="small">
            <DeleteIcon />
          </SvgIcon>
        </IconButton>
      </TableCell>
      <ConfirmationModal
        open={open}
        onSubmit={() => {
          onDelete(article.id);
        }}
        onClose={onClose}
      />
    </TableRow>
  );
};

export default ArticlesPageArticleDetails;
