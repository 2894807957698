import { Box } from "@mui/material";
import React from "react";
import { Spacing } from "src/types/enum";
import LessonEditPageSectionButton from "./LessonEditPageSectionButton";
import LessonEditPageSectionImage, { ImageWithUrl } from "./LessonEditPageSectionImage";

/**
 * Types
 */
interface ImageAndButtonDetails {
  buttonType: string;
  buttonAlignment: string;
  buttonText: string;
  buttonURL: string;
  fileUrl: ImageWithUrl | string;
}

interface Props {
  value: ImageAndButtonDetails;
  setValue: (value: ImageAndButtonDetails) => void;
}

const LessonEditPageSectionImageAndButton: React.FC<Props> = ({ value, setValue }: Props) => {
  return (
    <Box>
      <Box mb={Spacing.l}>
        <LessonEditPageSectionImage
          value={value.fileUrl}
          setValue={(fileUrl) => {
            setValue({ ...value, fileUrl });
          }}
        />
      </Box>
      <LessonEditPageSectionButton
        value={value}
        setValue={(button) => {
          setValue({ ...value, ...button });
        }}
      />
    </Box>
  );
};

export default LessonEditPageSectionImageAndButton;
