import { gql } from "@apollo/client";

export const QUERY_TOPICS = gql`
  query adminListPaginatedTopics($filters: AdminFilterTopicsInput!) {
    adminListPaginatedTopics(filters: $filters) {
      items {
        title
        id
        status
        theme {
          id
          title
          isGrammarLibrary
          isTopicLibrary
          isStandard
        }
      }
      total
    }
  }
`;

export const QUERY_TOPIC_THEMES_AND_ILLUSTRATIONS = gql`
  query adminGetTopicThemesAndIllustrations($id: String!) {
    adminGetTopic(id: $id) {
      id
      title
      theme {
        id
        title
      }
      comments {
        id
        createdAt
        message
        author {
          id
          name
          avatarUrl
        }
        replies {
          id
          createdAt
          message
          author {
            id
            name
            avatarUrl
          }
        }
      }
      status
      illustration {
        id
        title
        url
      }
      lessons {
        id
        kind
        title
      }
    }
    adminListThemes {
      id
      title
    }
    adminListIllustrations {
      id
      title
      url
    }
  }
`;
