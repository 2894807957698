import { type Theme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import React, { useCallback, useRef } from "react";
import ReactQuill, { Quill } from "react-quill";

import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  [key: string]: any;
  children?: any;
}

const toolbarOptions = [
  [{ header: [1, 2, 3, 4, false] }],
  ["bold", "underline", "italic"],
  [{ align: [] }],
  [{ list: "ordered" }, { list: "bullet" }],
  ["link"],
];

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    borderRadius: theme.shape.borderRadius,
    border: `1px solid ${theme.palette.action.disabled}`,
    "& .ql-container": {
      border: "none",
    },
    "& .ql-toolbar": {
      borderLeft: "none",
      borderTop: "none",
      borderRight: "none",
      borderBottom: `1px solid ${theme.palette.action.disabled}`,
    },
    "& .ql-editor": {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.common.white,
      paddingTop: theme.spacing(Spacing.sm),
      paddingBottom: theme.spacing(Spacing.sm),
      fontSize: theme.typography.h5.fontSize,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
    },
    "& .ql-picker-options": {
      border: "none",
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.background.default,
    },
    "& .ql-stroke, & .ql-picker-label, & .ql-picker-item": {
      color: theme.palette.action.active,
      stroke: theme.palette.action.active,
    },
    "& .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options": {
      borderRadius: theme.shape.borderRadius,
      borderColor: theme.palette.action.disabled,
    },
    "& .ql-snow.ql-toolbar .ql-active .ql-stroke .ql-stroke, & .ql-snow.ql-toolbar .ql-picker-label:hover, & .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke, & .ql-snow.ql-toolbar .ql-picker-item.ql-selected, & .ql-snow.ql-toolbar .ql-picker-item:hover, & .ql-snow.ql-toolbar .ql-picker-label.ql-active, & .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke":
      {
        color: theme.palette.primary.main,
        stroke: theme.palette.primary.main,
      },
  },
}));

Quill.register("#editor", {
  modules: {
    toolbar: toolbarOptions,
  },
});

const RichTextEditor: React.FC<Props> = ({ className, value, children, ...rest }) => {
  const classes = useStyles();

  const quillRef = useRef<ReactQuill | null>(null);

  const handleRef = useCallback((ref: ReactQuill) => {
    if (ref) {
      ref.getEditor().root.setAttribute("spellcheck", "false");
    }

    quillRef.current = ref;
  }, []);

  return (
    <ReactQuill
      defaultValue={value ?? String()}
      ref={handleRef}
      modules={{
        toolbar: toolbarOptions,
      }}
      value={value ?? String()}
      className={classNames(classes.root, className)}
      {...rest}
    >
      {children}
    </ReactQuill>
  );
};

export default RichTextEditor;
