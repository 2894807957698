import { useMutation, useQuery } from "@apollo/client";
import { type ApolloError } from "@apollo/client/errors";
import { useSnackbar } from "notistack";
import { append } from "ramda";
import React, { useCallback, useState } from "react";
import { useParams } from "react-router";

import { LESSON_NAMES } from "src/helpers/const";

import { CREATE_SECTION, DELETE_SECTION, EDIT_SECTION } from "../gql/mutations/section";
import { QUERY_LESSON_SECTIONS } from "../gql/queries/lesson";

import { type SectionModel } from "../types/graphql";

import {
  add,
  enhanceMeta,
  enhanceMetaOnChild,
  insertElementAt,
  move,
  remove,
  update,
} from "../helpers/sortable";

/**
 * Types
 */
interface Props {
  children: JSX.Element | JSX.Element[];
}

interface ContextType {
  title?: string;
  lessonId?: string;
  sections: SectionModel[];
  loading: boolean;
  error?: ApolloError;
  onCreateSection: () => Promise<void>;
  onSaveSection: (sectionId: string) => Promise<void>;
  onDeleteSection: (sectionId: string) => Promise<void>;
  onAddElement: (sectionId: string, item: unknown) => void;
  onEditElement: (sectionId: string, index: number, value: string) => void;
  onDeleteElement: (sectionId: string, index: number) => void;
  onEnhanceElementMeta: (sectionId: string, index: number, key: string, value: string) => void;
  onMoveElement: (dragIndex: number, hoverIndex: number, sectionId?: string) => void;
  onInsertElement: (sectionId: string, index: number, item: unknown) => void;
  onEnhanceElementMetaForChild: (
    sectionId: string,
    parentIndex: number,
    childIndex: number,
    key: string,
    value: string,
  ) => void;
}

/**
 * Context
 */
const LessonContext: React.Context<ContextType> = React.createContext({} as ContextType);

/**
 * Provider
 */
export const LessonProvider: React.FC<Props> = ({ children }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const { lessonId } = useParams<Record<string, string>>();

  const [sections, setSections] = useState<SectionModel[]>([]);
  const [title, setTitle] = useState<string | undefined>(undefined);

  const { loading, error } = useQuery(QUERY_LESSON_SECTIONS, {
    fetchPolicy: "network-only",
    variables: {
      id: lessonId,
    },
    onCompleted: (data) => {
      if (data?.adminGetLesson?.sections) {
        const title = data?.adminGetLesson?.title ?? LESSON_NAMES.get(data?.adminGetLesson?.kind);
        setTitle(title);
        setSections(data?.adminGetLesson?.sections);
      }
    },
  });

  const [editSection] = useMutation(EDIT_SECTION);
  const [createSection] = useMutation(CREATE_SECTION);
  const [deleteSection] = useMutation(DELETE_SECTION);

  const onCreateSection = useCallback(async () => {
    const variables = {
      input: {
        lessonID: lessonId,
        order: sections.length,
      },
    };

    try {
      const { data } = await createSection({ variables });
      setSections(append(data?.adminCreateSection, sections));
      enqueueSnackbar("Section successfully created", { variant: "success" });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  }, [createSection, enqueueSnackbar, lessonId, sections]);

  const onSaveSection = useCallback(
    async (sectionId: string) => {
      const order = sections.findIndex((section) => section.id === sectionId);

      const variables = {
        id: sectionId,
        input: {
          order,
          elements: sections[order]?.elements,
        },
      };

      try {
        await editSection({ variables });
        enqueueSnackbar("Section successfully saved", { variant: "success" });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [editSection, enqueueSnackbar, sections],
  );

  const onDeleteSection = useCallback(
    async (sectionId: string) => {
      const variables = {
        id: sectionId,
      };

      try {
        await deleteSection({ variables });
        setSections(sections.filter((sectionModel) => sectionModel.id !== sectionId));
        enqueueSnackbar("Section successfully deleted", { variant: "success" });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: "error" });
      }
    },
    [deleteSection, enqueueSnackbar, sections],
  );

  const onAddElement = useCallback(
    (sectionId: string, item: unknown) => {
      setSections(add(sectionId, sections, item));
    },
    [sections],
  );

  const onDeleteElement = useCallback(
    (sectionId: string, index: number) => {
      setSections(remove(sectionId, sections, index));
    },
    [sections],
  );

  const onEditElement = useCallback((sectionId: string, index: number, value: unknown) => {
    setSections((previousSections) => update(sectionId, previousSections, index, value));
  }, []);

  const onMoveElement = useCallback(
    (dragIndex: number, hoverIndex: number, sectionId?: string) => {
      if (sectionId && dragIndex !== hoverIndex) {
        setSections(move(sectionId, sections, dragIndex, hoverIndex));
      }
    },
    [sections],
  );

  const onInsertElement = useCallback(
    (sectionId: string, index: number, item: unknown) => {
      setSections(insertElementAt(sectionId, sections, index, item));
    },
    [sections],
  );

  const onEnhanceElementMeta = useCallback(
    (sectionId: string, index: number, key: string, value: unknown) => {
      setSections(enhanceMeta(sectionId, sections, index, key, value));
    },
    [sections],
  );

  const onEnhanceElementMetaForChild = useCallback(
    (sectionId: string, parentIndex: number, childIndex: number, key: string, value: unknown) => {
      setSections(enhanceMetaOnChild(sectionId, sections, parentIndex, childIndex, key, value));
    },
    [sections],
  );

  return (
    <LessonContext.Provider
      value={{
        lessonId,
        title,
        sections,
        loading,
        error,
        onCreateSection,
        onSaveSection,
        onDeleteSection,
        onAddElement,
        onEditElement,
        onDeleteElement,
        onMoveElement,
        onInsertElement,
        onEnhanceElementMeta,
        onEnhanceElementMetaForChild,
      }}
    >
      {children}
    </LessonContext.Provider>
  );
};

export default LessonContext;
