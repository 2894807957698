import DeleteIcon from "@mui/icons-material/Delete";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Box, Grid, IconButton, type Theme, useTheme } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import { isNil, not } from "ramda";
import React, { Fragment, type LegacyRef, memo, useContext, useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

import { ELEMENT_TEMPLATES_TYPE } from "../helpers/const";
import { Sizing, Spacing } from "../types/enum";
import { type Element, ElementKind } from "../types/graphql";

import LessonEditPageSectionAttachment from "./LessonEditPageSectionAttachment";
import LessonEditPageSectionBanner from "./LessonEditPageSectionBanner";
import LessonEditPageSectionButton from "./LessonEditPageSectionButton";
import LessonEditPageSectionChooseLayout from "./LessonEditPageSectionChooseLayout";
import LessonEditPageSectionDivider from "./LessonEditPageSectionDivider";
import LessonEditPageSectionEmbedded from "./LessonEditPageSectionEmbedded";
import LessonEditPageSectionIdeas from "./LessonEditPageSectionIdeas";
import LessonEditPageSectionImage from "./LessonEditPageSectionImage";
import LessonEditPageSectionImageAndButton from "./LessonEditPageSectionImageAndButton";
import LessonEditPageSectionLinks from "./LessonEditPageSectionLinks";
import LessonEditPageSectionParagraph from "./LessonEditPageSectionParagraph";
import LessonEditPageSectionQuestions from "./LessonEditPageSectionQuestions";
import LessonEditPageSectionSpacing from "./LessonEditPageSectionSpacing";
import LessonEditPageSectionTitle from "./LessonEditPageSectionTitle";
import LessonEditPageSectionVideo from "./LessonEditPageSectionVideo";

import LessonContext from "../context/LessonContext";

/**
 * Types
 */
export interface LessonEditPageSectionElementProps {
  sectionId?: string;
  index: number;
  draggable?: boolean;
  element: Element;
  onDeleteElement: () => void;
  onEditElement: (value: string) => void;
  setElementMeta?: (key: string, value: string) => void;
  showGridDescription?: boolean;
  showAddRowButton?: boolean;
}

/**
 * Constants
 */
const ELEMENTS = new Map<ElementKind, any>([
  [ElementKind.Title, LessonEditPageSectionTitle],
  [ElementKind.Paragraph, LessonEditPageSectionParagraph],
  [ElementKind.Video, LessonEditPageSectionVideo],
  [ElementKind.Questions, LessonEditPageSectionQuestions],
  [ElementKind.Image, LessonEditPageSectionImage],
  [ElementKind.Spacing, LessonEditPageSectionSpacing],
  [ElementKind.Divider, LessonEditPageSectionDivider],
  [ElementKind.Links, LessonEditPageSectionLinks],
  [ElementKind.Ideas, LessonEditPageSectionIdeas],
  [ElementKind.Attachment, LessonEditPageSectionAttachment],
  [ElementKind.Grid, LessonEditPageSectionChooseLayout],
  [ElementKind.Button, LessonEditPageSectionButton],
  [ElementKind.Banner, LessonEditPageSectionBanner],
  [ElementKind.ImageAndButton, LessonEditPageSectionImageAndButton],
  [ElementKind.Embedded, LessonEditPageSectionEmbedded],
]);

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  drag: {
    cursor: "-webkit-grab",
    "&:active": {
      cursor: "-webkit-grabbing",
    },
  },
  card: {
    width: "100%",
    backgroundColor: theme.palette.background.default,
    paddingTop: theme.spacing(Spacing.sm),
    paddingLeft: theme.spacing(Spacing.sm),
    paddingBottom: theme.spacing(Spacing.sm),
  },
  dragging: {
    opacity: 0,
  },
  dropArea: {
    paddingBottom: theme.spacing(Spacing.ml),
    backgroundImage: "inherit",
  },
}));

const LessonEditPageSectionElement: React.FC<LessonEditPageSectionElementProps> = ({
  sectionId,
  draggable,
  element,
  onDeleteElement,
  onEditElement,
  setElementMeta,
  index,
  showGridDescription,
}: LessonEditPageSectionElementProps) => {
  const theme = useTheme();
  const classes = useStyles();
  const { onInsertElement, onMoveElement } = useContext(LessonContext);
  const ref = useRef<HTMLDivElement | null>(null);

  // useDrag - the list item is draggable
  const [{ isDragging }, dragRef] = useDrag({
    type: "ElementKind",
    item: index,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  // useDrop - the list item is also a drop area
  const [, dropRef] = useDrop({
    accept: "ElementKind",
    hover: (item: any, monitor: any) => {
      const dragIndex = item ? item.index : undefined;
      const hoverIndex = index;
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        hoverBoundingRect && (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const hoverActualY =
        monitor?.getClientOffset() && hoverBoundingRect
          ? monitor.getClientOffset().y - hoverBoundingRect.top
          : null;

      // if dragging down, continue only when hover is smaller than middle Y
      if (
        hoverActualY &&
        hoverMiddleY &&
        hoverIndex &&
        dragIndex < hoverIndex &&
        hoverActualY < hoverMiddleY
      )
        return;
      // if dragging up, continue only when hover is bigger than middle Y
      if (
        hoverActualY &&
        hoverIndex &&
        hoverMiddleY &&
        dragIndex > hoverIndex &&
        hoverActualY > hoverMiddleY
      )
        return;

      if (sectionId && not(isNil(index)) && not(isNil(hoverIndex))) {
        onMoveElement(dragIndex, hoverIndex, sectionId);
      }

      item.index = hoverIndex;
    },
  });

  // Join the 2 refs together into one (both draggable and can be dropped on)
  const dragDropRef = dragRef(dropRef(ref));

  const [, dropBeforeRef] = useDrop({
    accept: ELEMENT_TEMPLATES_TYPE,
    drop: (item, monitor) => {
      if (monitor.didDrop()) {
        return null;
      }
      if (sectionId) {
        onInsertElement(sectionId, index, item);
      }
    },
  });
  const [, dropAfterRef] = useDrop({
    accept: ELEMENT_TEMPLATES_TYPE,
    drop: (item, monitor) => {
      const nextElementIndex = index + 1;
      if (monitor.didDrop()) {
        return null;
      }
      if (sectionId) {
        onInsertElement(sectionId, nextElementIndex, item);
      }
    },
  });

  return (
    <Grid item xs={Sizing.Full}>
      {/* area used to drop before current element */}
      {(index === 0 && element.kind !== ElementKind.Grid) ||
      (index === 0 && showGridDescription) ? (
        <div ref={dropBeforeRef} className={classes.dropArea} />
      ) : null}
      <span ref={dragDropRef as LegacyRef<HTMLSpanElement> | undefined}>
        <Box className={classNames(classes.card, { [classes.dragging]: isDragging })}>
          <Box display="flex" alignItems="center">
            {isDragging || draggable ? (
              <Box mr={Spacing.m}>
                <DragIndicatorIcon
                  htmlColor={theme.palette.action.active}
                  className={classes.drag}
                />
              </Box>
            ) : null}

            {React.createElement(ELEMENTS.get(element?.kind) ?? <Fragment />, {
              value: element?.value,
              setValue: (value: string) => {
                onEditElement(value);
              },
              setElementMeta,
              parent: element,
              index,
              sectionId,
              showGridDescription,
            })}

            <Box display="flex" flexDirection="column" ml={Spacing.s}>
              <IconButton onClick={onDeleteElement} size="large">
                <DeleteIcon />
              </IconButton>
            </Box>
          </Box>
        </Box>
      </span>
      {/* area used to drop after current element */}
      {element.kind !== ElementKind.Grid ? (
        <div ref={dropAfterRef} className={classes.dropArea} />
      ) : null}
    </Grid>
  );
};

export default memo(LessonEditPageSectionElement);
