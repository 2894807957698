import { useMutation, useQuery } from "@apollo/client";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, CircularProgress, Grid, Link, type Theme, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useSnackbar } from "notistack";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { Link as RouterLink } from "react-router-dom";

import { DELETE_COMMENT } from "../gql/mutations/comments";
import { DELETE_TOPIC } from "../gql/mutations/topic";
import { QUERY_TOPIC_THEMES_AND_ILLUSTRATIONS } from "../gql/queries/topic";

import { useOnOffSwitch } from "../helpers/hooks";
import { truncateWithEllipses } from "../helpers/utils";
import { Sizing, Spacing } from "../types/enum";

import TopicEditPageFormCont from "../containers/TopicEditPageFormCont";
import TopicEditPageLessonsCont from "../containers/TopicEditPageLessonsCont";

import ConfirmationModal from "../components/ConfirmationModal";
import { type CommentModel } from "../types/graphql";

/**
 * Styles
 */
const useStyles = makeStyles((theme: Theme) => ({
  delete: {
    marginRight: theme.spacing(Spacing.sm),
    color: theme.palette.common.white,
    backgroundColor: theme.palette.error.main,
    "&:hover": {
      backgroundColor: theme.palette.error.dark,
    },
  },
}));

const TopicEditPage: React.FC = () => {
  const [open, onOpen, onClose] = useOnOffSwitch();
  const [deleteTopic] = useMutation(DELETE_TOPIC);

  const classes = useStyles();
  const history = useNavigate();
  const params = useParams<Record<string, string>>();

  const { data, loading } = useQuery(QUERY_TOPIC_THEMES_AND_ILLUSTRATIONS, {
    variables: {
      id: params?.topicId,
    },
    fetchPolicy: "network-only",
  });

  const [deleteComment] = useMutation(DELETE_COMMENT);

  const { enqueueSnackbar } = useSnackbar();

  if (loading) {
    return <CircularProgress />;
  }

  const topic = data?.adminGetTopic;
  const themes = data?.adminListThemes;
  const illustrations = data?.adminListIllustrations;

  const onSubmit = async () => {
    const variables = {
      id: topic.id,
    };

    try {
      await deleteTopic({ variables });
      enqueueSnackbar("Topic successfully deleted", { variant: "success" });
      history("/topics");
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: "error" });
    }
  };

  const onDeleteComment = async (commentID: string): Promise<any> => {
    const variables = {
      commentID,
    };

    await deleteComment({ variables });
  };

  const onDeleteComments = async (): Promise<any> => {
    try {
      await Promise.all(
        data?.adminGetTopic?.comments.map((comment: CommentModel) => onDeleteComment(comment.id)),
      );
      enqueueSnackbar("Comment was deleted successfully", { variant: "success" });
    } catch (_error) {
      enqueueSnackbar("There was a problem deleting the comment", { variant: "error" });
    }
  };

  return (
    <>
      <Box display="flex" alignItems="center" mb={Spacing.m}>
        <Link color="inherit" component={RouterLink} to={"/topics"}>
          <Box mr={Spacing.sm}>
            <ArrowBackIcon />
          </Box>
        </Link>

        {topic?.title ? (
          <Typography variant="h3" color="textPrimary">
            {truncateWithEllipses(topic.title)}
          </Typography>
        ) : null}

        <Box ml="auto">
          <Button className={classes.delete} variant="contained" color="secondary" onClick={onOpen}>
            Delete
          </Button>

          <Button
            className={classes.delete}
            variant="contained"
            color="secondary"
            onClick={onDeleteComments}
          >
            Delete Comments
          </Button>

          <Link
            color="inherit"
            href={`${import.meta.env.VITE_URL}/topic/${topic?.id}/lesson`}
            target="_blank"
          >
            <Button variant="contained">View</Button>
          </Link>
        </Box>

        <ConfirmationModal open={open} onSubmit={onSubmit} onClose={onClose} />
      </Box>

      <Grid spacing={Spacing.sm} container>
        <Grid xs={Sizing.Full} item>
          <TopicEditPageFormCont topic={topic} themes={themes} illustrations={illustrations} />
        </Grid>

        <Grid xs={Sizing.Full} item>
          <TopicEditPageLessonsCont lessons={topic?.lessons} topicId={topic.id} />
        </Grid>
      </Grid>
    </>
  );
};

export default TopicEditPage;
