import { Box, FormControl, FormLabel, TextField } from "@mui/material";
import debounce from "lodash.debounce";
import React from "react";

import { DEBOUNCE_TIME } from "../helpers/const";
import { Spacing } from "../types/enum";

/**
 * Types
 */
interface Props {
  value?: string;
  setValue: (text: string) => void;
}

const LessonEditPageSectionTitle: React.FC<Props> = ({ value, setValue }: Props) => {
  const onChange = debounce(setValue, DEBOUNCE_TIME);

  return (
    <FormControl fullWidth>
      <Box mb={Spacing.s}>
        <FormLabel>Title</FormLabel>
      </Box>
      <TextField
        fullWidth
        defaultValue={value}
        name="title"
        placeholder="Title"
        variant="outlined"
        onChange={({ target }: React.ChangeEvent<HTMLInputElement>) => onChange(target.value)}
      />
    </FormControl>
  );
};

export default LessonEditPageSectionTitle;
